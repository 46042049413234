<template lang="pug">
SwitchGroup
  div(class='pt-2 flex items-center gap-x-2.5')
    Switch(
      v-slot='{ checked }'
      as='template'
      :disabled='isDisabled'
      :model-value='isChecked'
      @update:model-value='toggleCompare()'
    )
      button(
        class='disabled:border-gray-lighter relative inline-flex justify-center items-center h-5 w-5 border-2 border-solid rounded focus:outline-none'
        :class='checked ? "bg-action border-action" : "border-gray-dark"'
      )
        img(v-if='checked' src='/images/checkmarkThick.svg' alt='checkmark' class='w-3 h-3' height='12' width='12')

    SwitchLabel(class='flex-1' :class='{ "text-gray-light": isDisabled }') Compare
</template>

<script setup lang="ts">
import { SwitchGroup, SwitchLabel, Switch, provideUseId } from '@headlessui/vue'

// We need to do this to so headlessUI doesn't cause hydration errors/
// See: https://github.com/tailwindlabs/headlessui/issues/2913#issuecomment-1924681165
provideUseId(() => useId())

const compare = useCompare()

const { listing } = defineProps<{ listing: ProductListing }>()

const isChecked = computed(() => {
  return compare.list.value.findIndex((compareListing) => compareListing.skuSlug === listing.skuSlug) > -1
})

const isDisabled = computed(() => {
  return compare.list.value.length >= 3 && !isChecked.value
})

function toggleCompare() {
  if (isChecked.value) compare.removeProduct(listing.skuSlug)
  else compare.addProduct(listing)
}
</script>
