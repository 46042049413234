export default function () {
  const router = useRouter()

  const compareListings = useState<ProductListing[]>('compareListings', () => [])

  function addProduct(listing: ProductListing) {
    // Try to find the product in the current compare list.
    const index = compareListings.value.findIndex((compareListing) => compareListing.skuSlug === listing.skuSlug)

    // If product already exists, don't add it again.
    if (index > -1) return

    compareListings.value.push(listing)
  }

  function removeProduct(skuSlug: string) {
    // Find the skuSlug in the compare list
    const index = compareListings.value.findIndex((compareListing) => compareListing.skuSlug === skuSlug)

    // If skuSlug does not exist just return
    if (index === -1) return

    compareListings.value.splice(index, 1)
  }

  function clearProducts() {
    compareListings.value = []
  }

  // Watch for route changes and clear the compare list if needed
  router.afterEach((to, from) => {
    // If the list is empty early out
    if (compareListings.value.length < 1) return
    // Clear the compare list if the route is anything but the current category
    if (to.path !== from.path) clearProducts()
  })

  return {
    list: readonly(compareListings),
    addProduct,
    removeProduct,
    clearProducts,
  }
}
