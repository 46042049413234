<template lang="pug">
div(class='flex flex-wrap -mx-3 bg-white')
  div(v-if='imageUrl' class='p-3 w-full lg:w-1/3')
    img(:src='imageUrl' :alt='imageAlt' class='mx-auto')

  div(class='p-3 w-full lg:flex-1 text-center')
    h3 Well That's A Bummer...

    p(class='mb-5') {{ subtext }}

    BaseButton(v-if='showResetButton' color='WARNING' class='mx-1' style='width: 200px' @click='clearFitment') Reset My Vehicle
    BaseButton(to='/' class='mx-1' style='width: 200px') Go To HomePage
</template>

<script setup lang="ts">
const fitmentStore = useFitmentStore()
const { $storyblok, $sitewideConfig, $uiEvents } = useNuxtApp()
const route = useRoute()

const imageUrl = computed(() => {
  const source = $sitewideConfig.styles?.content.noResults?.filename

  return source ? $storyblok.formatSrc(source) : null
})

const imageAlt = computed(() => {
  return $sitewideConfig.styles?.content.noResults?.alt || ''
})

const subtext = computed(() => {
  let text = "We don't"

  if (route.name?.toString().includes('category')) text += ' carry any products in this category'
  else if (route.name?.toString().includes('brand')) text += ' carry any products from this brand'
  else if (route.name?.toString().includes('special')) text += ' have any products on sale'
  else if (route.name?.toString().includes('search')) text += ' have any products that matched your search'
  else text += ' carry any products in this area'

  if (fitmentStore.fitmentFormatted) text += ` for your ${fitmentStore.fitmentFormatted}`

  text += '.'

  return text
})

const showResetButton = computed(() => {
  const routeName = route.name?.toString() || ''
  return routeName.indexOf('-mmy') > -1
})

function clearFitment() {
  const params = Object.assign({}, route.params)
  delete params.makeSlug
  delete params.modelSlug
  delete params.year

  const newRoute = {
    name: route.name?.toString().replace('-mmy', ''),
    params,
  }

  navigateTo(newRoute)
}

onMounted(() => {
  $uiEvents.$emit('noResultsViewed', route)
})
</script>
